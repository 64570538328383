import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import BlockRouter from '../components/BlockRouter';
import GradientWrapper from '../components/GradientWrapper';
import HelmetMichelberger from '../components/HelmetMichelberger';
import Information from '../components/Information';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import PageDescription from '../components/PageDescription';

/**
 * This page is identical to a Menu page. It should be made more generic
 * but currently, we build pages and their paths according to page _type_
 * and not hierarchy. This means we would e.g. fetch all "Menu" (or "Generic")
 * pages and build them under the same parent slug, which is unacceptable.
 * We would need to refactor the pages to all operate with a navChildren
 * field, then fetch and build each page type separately per parent.
 * This is a major refactor so is not being undertaken now, but should be
 * a first step before any further non-trivial changes are implemented.
 */
const Farm = ({ data, location, pageContext }) => {
  const { languageSwitch } = pageContext;
  const {
    body, description, gradient, infoButtonLabel, infoButtonUrl, infoText, name,
    seoMetaTags,
  } = data.allDatoCmsFarm.edges[0].node;
  const footerData = data.allDatoCmsFooter.edges[0].node;

  return (
    <Layout languageSwitch={languageSwitch} location={location}>
      <GradientWrapper footerData={footerData} gradient={gradient} languageSwitch={languageSwitch}>
        <HelmetMichelberger seo={seoMetaTags} />
        <PageTitle>{name}</PageTitle>
        <PageDescription isSmall>{description}</PageDescription>
        <Information buttonLabel={infoButtonLabel} buttonUrl={infoButtonUrl} text={infoText} />
        <For each="block" index="index" of={body}>
          <BlockRouter key={block.id} block={block} index={index} />
        </For>
      </GradientWrapper>
    </Layout>
  );
};

Farm.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default Farm;

export const query = graphql`
  query Farm($locale: String!, $slug: String!) {
    allDatoCmsFarm(filter: { slug: { eq: $slug }, locale: { eq: $locale } }) {
      edges {
        node {
          gradient {
            color {
              hex
            }
          }
          name
          slug
          description
          infoButtonLabel
          infoButtonUrl
          infoText
          ...GatsbyDatoCmsFarmBody
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
        }
      }
    }
    allDatoCmsFooter(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          ...footerFields
        }
      }
    }
  }
`;
